
import {get,post,put,del,getDrugExel} from "./base";


// 前台操作日志
export function getMemberLog(pageNum,type,start,end){
    return get('/v3/logmembers',{pageNum,type,start,end})
}
// 后台操作日志
export function getMainLog(data){
    return get('/v3/logadmins',data)
}



//点播点击记录/文章点击记录

//type:ARTICLE_SINGLE文章点击记录;VOD_SINGLE点播点击记录
export function getUserActionLog(data){
    return get('/v3/userAction/content',data)
}

//获取评论类型
export function getCommentType(){
    return get('/v3/userAction/comment/type')
}
//获取评论
export function getUserActionLog_comment(data){
    return get('/v3/userAction/comment',data)
}
//删除评论
export function delUserActionLog_comment(id){
    return del('/v3/userAction/comment/' +id)
}
//获取点赞类型
export function getPraiseType(){
    return get('/v3/userAction/praise/type')
}
//获取点赞
export function getUserActionLog_praise(data){
    return get('/v3/userAction/praise',data)
}
//获取收藏类型
export function getCollectionType(){
    return get('/v3/userAction/collection/type')
}
//获取收藏
export function getUserActionLog_collection(data){
    return get('/v3/userAction/collection',data)
}

export function getShareType(){
    return get('/v3/userAction/share/type')
}

export function getUserActionLog_share(data){
    return get('/v3/userAction/share',data)
}

// 发送邮件记录获取菜单  
export function getEmailType(){
    return get('/v3/caseExportList/email/type')
}
// 发送邮件记录 -- 文献指南 
export function getEmailRecord(data){ 
    return get('/v3/caseExportList/email/record',data)
}
// 导出邮件发送记录
export function getEmailExcel(data){
    return getDrugExel("/v3/caseExportList/email/export",data);
}