<template>
  <div class="UseDailyPracticeRecord"  :style="{minHeight: screeHeight+'px'}">
    <div class="Previous_left">
      <div class="Previous_module">分类</div>
      <a-menu v-model="selectId" mode="inline" style="width: 100%">
        <a-menu-item
            v-for="(item, index) in templateList"
            :key="item.code"
            @click="selectMenu(item)"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </div>
    <div class="Previous_right">
      <div class="log">
        <a-spin :spinning="spinning">
          <div class="search">
            <div>
              操作日期&nbsp;
              <a-range-picker v-model="searchValue" class="rangePicker" @change="dateChange"/>
              <a-button style="margin-left: 20px" type="primary" @click="searchBtn">查询</a-button>
            </div>
            <a-button @click="exportBtn">
              <a-icon style="color: #00A854;" type="file-excel"/>
              批量导出
            </a-button>

          </div>
          <div class="table">

            <a-alert v-if="routerType==='collection' && selectId[0]==='ARTICLE'" message="目前只能通过APP收藏"
                     type="info" show-icon style="margin-bottom: 15px" />
            <a-alert v-if="routerType==='share' && (selectId[0]==='ARTICLE'|| selectId[0]==='VOD')" message="通过APP分享，没有记录"
                     type="info" show-icon style="margin-bottom: 15px" />

            <a-alert v-if="routerType==='share' && selectId[0]==='COMMUNITY_POST'" message="只记录了APP IOS版分享的记录"
                     type="info" show-icon style="margin-bottom: 15px" />
            <a-table
                :columns="columns"
                :data-source="table"
                :pagination="false"
                :rowKey="record=>record.id"
                :scroll="{ x: 1500}">
              <span slot="location" slot-scope="text, record">
                {{ returnTitle(record.location) }}
              </span>
              <span slot="contentType" slot-scope="text, record">
                {{ findValueByKey(content_type, record.contentType) }}
              </span>
              <span slot="channel" slot-scope="text, record">
                <span v-if="text.channel == '' || text.channel == null">未知</span>
                <span v-else>{{text.channel}}</span>
                <!-- {{ record.channel !== null ? findValueByKey(channel, record.channel) : '未知' }} -->
              </span>
              <span slot="targetTitle" slot-scope="text, record" :title="record.targetTitle || record.title" style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;">
                {{ record.targetTitle || record.title}}
              </span>
              <span slot="content_value" slot-scope="text, record" :title="record.content" style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient: vertical;">
                {{ record.content}}
              </span>
              <span slot="operation" slot-scope="text, record">
                          <a-popconfirm
                              title="确定删除?"
                              ok-text="是"
                              cancel-text="否"
                              @confirm="delBtn(record.id)"
                          >
            <a >删除</a>
            </a-popconfirm>
              </span>
            </a-table>
            <!--分页-->
            <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination v-model="pagination.current"
                            :page-size-options="pageSizeOptions"
                            :page-size.sync="pagination.pageSize"
                            :total="pagination.total"
                            show-quick-jumper show-size-changer
                            @change="pageChange"
                            @showSizeChange="pageChange"/>
            </div>
          </div>
        </a-spin>
      </div>

    </div>
  </div>
</template>

<script>

import {
  delUserActionLog_comment,
  getCollectionType,
  getCommentType, getPraiseType, getShareType, getUserActionLog_collection,
  getUserActionLog_comment, getUserActionLog_praise, getUserActionLog_share
} from "@/service/operationLog";
import headerConfig from "@/service/api_header_config";
import codeList from "@/assets/configurationFiles/EduDrugCodeList.json";
export default {
  name: "UseDailyPracticeRecord",
  data() {
    return {
      screeHeight: document.body.clientHeight-64-15,
      routerType:this.$router.currentRoute.name,
      menuType: this.$store.state.content_type_menu,
      selectId: [],
      templateList: [],
      spinning: false,
      location: this.$store.state.location,
      content_type: this.$store.state.content_type,
      channel: this.$store.state.channel,
      searchValue: [],
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      table: [],
      columns: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '内容标题',
          scopedSlots: {customRender: 'targetTitle'},
          align: 'center',
        },
        {
          title: '操作渠道',
          align: 'center',
          scopedSlots: {customRender: "channel"},
        },
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
      ],
      pageSizeOptions: ['10', '30', '50'],
    }
  },
  watch: {
    $route(to) { //监控路由地址变更
      this.pagination.current=1
      this.searchValue=[]
      this.routerType=to.name
      if (to.name === 'comments') {
        this.columns = [
          {
            title: '评论内容',
            align: 'center',
            dataIndex: 'content',
          },
          {
            title: '评论状态',
            align: 'center',
            dataIndex: 'isDeleted',
            customRender(value) {
              return value === 0 ? '正常' : '作者已删除'
            }
          },
          {
            title: '姓名',
            align: 'center',
            dataIndex: 'name',
          },
          {
            title: '医院',
            align: 'center',
            dataIndex: 'hospitalName',
          }, {
            title: '科室',
            dataIndex: 'hospitalOffices',
            align: 'center',
          },
          {
            title: '职称',
            dataIndex: 'jobTitle',
            align: 'center',
          },
          {
            title: '内容标题',
            scopedSlots: {customRender: 'targetTitle'},
            align: 'center',
          },
          {
            title: '操作渠道',
            align: 'center',
            scopedSlots: {customRender: "channel"},
          },
          {
            title: '操作时间',
            align: 'center',
            dataIndex: 'createdTime',
          },
          {
            title: "操作",
            width:'80px',
            scopedSlots: {customRender: "operation"},
            fixed: 'right',
          },
        ]
      }else {
        this.columns = this.columns.filter(obj => obj.title !== '评论内容');
        this.columns = this.columns.filter(obj => obj.title !== '评论状态');
        this.columns = this.columns.filter(obj => obj.title !== '操作');
      }
      this.getLeftTemplate(); //获取左侧菜单列表
    },
  },


  mounted() {
    if (this.$router.currentRoute.name==='comments'){
      this.columns = [
        {
          title: '评论内容',
          align: 'center',
          scopedSlots: {customRender: 'content_value'},
          dataIndex: 'content',
        },
        {
          title: '评论状态',
          align: 'center',
          dataIndex: 'isDeleted',
          customRender(value) {
            return value === 0 ? '正常' : '作者已删除'
          }
        },
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '内容标题',
          scopedSlots: {customRender: 'targetTitle'},
          align: 'center',
        },
        {
          title: '操作渠道',
          align: 'center',
          scopedSlots: {customRender: "channel"},
        },
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
        {
          title: "操作",
          scopedSlots: {customRender: "operation"},
          width:'80px',
          fixed: 'right',
        },
      ]
    }else {
      this.columns = this.columns.filter(obj => obj.title !== '评论内容');
      this.columns = this.columns.filter(obj => obj.title !== '评论状态');
      this.columns = this.columns.filter(obj => obj.title !== '操作');
    }
     this.getLeftTemplate(); //获取左侧菜单列表
  },
  methods: {
    returnTitle(code) {
      let list = codeList;
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].code) {
          return list[i].title;
        }
      }
    },
    //获取左侧菜单列表
    async getLeftTemplate() {
      const res = this.routerType==='comments' ?await getCommentType(): //评论
          this.routerType==='collection' ?await getCollectionType(): //收藏
              this.routerType==='thumbsUp' ?await getPraiseType(): //点赞
                  this.routerType==='share' ?await getShareType(): //分享
                      '';
      if (res.statuscode === 200) {
        this.templateList = []
        for (const key of res.data) {
          if (this.menuType.hasOwnProperty(key)) {
            this.templateList.push({
              name: this.menuType[key],
              code: key
            });
          }
        }
        this.selectId[0] = this.templateList[0].code;  //初始文章
        this.getData()
      }
    },
    selectMenu(row) {
      console.log(row)
      this.pagination.current = 1
      this.searchValue = []
      // this.selectId[0] = "YXGS";
      this.selectId[0] = row.code;
      this.getData()
      if(this.selectId[0] == 'YXGS'){
        this.columns =  [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '中文名称',
          scopedSlots: {customRender: 'targetTitle'},
          align: 'center',
        },
        {
          title: '操作渠道',
          align: 'center',
          scopedSlots: {customRender: "channel"},
        },
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
      ]
      }else{
        this.columns =  [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '内容标题',
          scopedSlots: {customRender: 'targetTitle'},
          align: 'center',
        },
        {
          title: '操作渠道',
          align: 'center',
          scopedSlots: {customRender: "channel"},
        },
        {
          title: '操作时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
      ]
      }
    },
    //获取右侧

    async getData() {
      this.spinning = true
      const data = {
        curPage: this.pagination.current,
        pageSize: this.pagination.pageSize,
        startTime: this.searchValue[0],
        endTime: this.searchValue[1],
        type: this.selectId[0]
      }
      const ref = this.routerType==='comments' ?await getUserActionLog_comment(data): //评论
          this.routerType==='collection' ?await getUserActionLog_collection(data): //收藏
              this.routerType==='thumbsUp' ?await getUserActionLog_praise(data): //点赞
                  this.routerType==='share' ?await getUserActionLog_share(data): //分享
                      '';
      if (ref.statuscode === 200) {
        this.table = ref.data
        this.pagination.total = ref.count
      }
      this.spinning = false
    },

    dateChange(date, dateString) {
      this.pagination.current = 1
      this.searchValue = dateString
      this.getData()
    },
    searchBtn() {
      this.getData()
    },
    exportBtn() {
      let startTime=this.searchValue[0]===undefined?'':this.searchValue[0]
      let endTime = this.searchValue[1]===undefined?'':this.searchValue[1]
      let path = this.routerType==='comments'? '/v3/userAction/comment/export?': //评论
          this.routerType==='collection' ? '/v3/userAction/collection/export?': //收藏
              this.routerType==='thumbsUp' ? '/v3/userAction/praise/export?': //点赞
                  this.routerType==='share' ? '/v3/userAction/share/export?': //分享
                      '';
      let url =  headerConfig.api_header + path
          +'startTime='+startTime+'&endTime='+endTime+'&type='+this.selectId[0]
      window.open(url)
    },
    pageChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData()
    },

    findValueByKey(arr, key) {
      // 检查对象中是否存在给定的键
      if (arr.hasOwnProperty(key)) {
        // 如果存在，返回相应的属性值
        return arr[key];
      } else {
        // 如果不存在，返回 null 或者你认为适合的默认值
        return null;
      }
    },
    async delBtn(row){
      console.log(row)
      const del = await delUserActionLog_comment(row)
      if (del.statuscode===200){
        this.$message.success(del.message)
        this.getData()
      }else {
        this.$message.warn(del.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-menu {
  border: none;
}

.UseDailyPracticeRecord {
  margin: -15px -15px;
  display: flex;
  justify-content: space-between;

  .Previous_left {
    flex: 1;
    //background-color: #ee7474;
    .Previous_module {
      color: black;
      font-weight: bolder;
      margin-left: 25px;
      margin-top: 10px;
    }

  }

  .Previous_right {
    border-left: 15px solid #F0F2F5;
    width: 84%;

    .Previous_right_top {
    }

    .Previous_right_title {
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }

    .top_cont {
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    .EduRec-top-input {
      height: 50px;
      padding-top: 15px;
    }

    .activity-table {
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }

    .pageView {
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .pageLeft {
      font-size: 15px;
      color: #929292
    }
  }
}

.log {
  background-color: #f0f2f5;

  .search {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .table {
    background-color: #fff;
    padding: 20px;
  }
}

.pageView {
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}
::v-deep .ant-table-thead>tr>th {
  font-weight: bold;

}
.pageLeft {
  font-size: 15px;
  color: #929292
}
</style>
